import { useFontAwesome } from '@/plugins/font-awesome';
import Datepicker from '@vuepic/vue-datepicker';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './vuex-store';
import i18n from './plugins/i18n';
import * as Sentry from '@sentry/vue';
import './plugins/dayjs';

import './assets/tailwind.css';
import './styles/main.scss';
import './styles/betting-table.css';
import '@vuepic/vue-datepicker/dist/main.css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.component('VueDatepicker', Datepicker);

useFontAwesome(app);

const apiUrl = import.meta.env.VITE_API_HTTP;
if (apiUrl) {
  Sentry.init({
    app,
    dsn: 'https://183b0e3d97f53eecd46e636052ebaf75@o4507429629919232.ingest.us.sentry.io/4508506349633536',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', apiUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: 'test',
  });
}

app.use(pinia);
app.use(store);
app.use(router);
app.use(i18n);

app.mount('#app');
