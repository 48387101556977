export default {
  th: {
    cancel: 'ยกเลิก',
    remove: 'ลบ',
    submit: 'บันทึก',
    change: 'เปลี่ยน',
    changeOdds: 'ปรับราคา',
    loading: 'โหลดข้อมูล',
    dataNotFound: 'ไม่มีข้อมูล',
    ballStep: 'บอลสเต็ป',
    statement: 'รายงานการเงิน',
    fullBetList: 'รายการแบบเต็ม',
    result: 'ผลการแข่งขัน',
    account: 'ข้อมูลผู้ใช้',
    live: 'ถ่ายทอดสด',
    program: 'โปรแกรม',
    logout: 'ออกจากระบบ',
    username: 'ยูสเซอร์',
    signIn: 'เข้าสู่ระบบ',
    password: 'รหัสผ่าน',
    earlyMarket: 'ราคาล่วงหน้า',
    games: 'เกมส์',
    football: 'ฟุตบอล',
    outstanding: 'ยอดพนัน',
    betCredit: 'คงเหลือ',
    tickets: 'ตั๋ว',
    markets: 'ประเภท',
    myBets: 'รายการเล่นทั้งหมด',
    hdpOu: 'เต็มเวลา',
    fhHdpOu: 'ครึ่งแรก',
    oddsEven: '1x2 & คู่-คี่',
    fullBet: 'เต็มเวลา/ครึ่งแรก',
    noPendingBets: 'ไม่มีรายการตั๋ว',
    emptyTicket: 'ไม่มีตั๋วที่เลือก',
    maxPayout: 'จ่ายสูงสุด',
    print: 'พิมพ์ราคา',
    selectLeague: 'เลือกลีก',
    time: 'เวลา',
    fullTime: 'เต็มเวลา',
    event: 'คู่แข่งขัน',
    hdp: 'หน้าบอล',
    home: 'หน้าหลัก',
    away: 'ทีมเยือน',
    goal: 'ประตู',
    over: 'สูงกว่า',
    under: 'ต่ำกว่า',
    firstHalf: 'ครึ่งแรก',
    draw: 'เสมอ',
    odd: 'คี่',
    even: 'คู่',
    date: 'วันที่',
    remark: 'หมายเหตุ',
    type: 'ประเภท',
    totalAmount: 'ยอดรวม',
    totalTurnOver: 'ยอดแทงรวม',
    totalWinLose: 'ยอดได้เสียรวม',
    commission: 'คอมฯ.',
    betting: 'เดิมพัน',
    winLoseTransaction: 'รายการได้เสีย',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัส',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์',
    sunday: 'วันอาทิตย์',
    chooseDate: 'เลือกวันที่',
    today: 'วันนี้',
    yesterday: 'เมื่อวาน',
    ok: 'ตกลง',
    match: 'คู่แข่งขัน',
    htScore: 'ผลครึ่งแรก',
    ftScore: 'ผลเต็มเวลา',
    status: 'สถานะ',
    resultTitle: 'ผลการแข่งขัน',
    betListTitle: 'รายการแบบเต็ม',
    statementTitle: 'รายงานการเงิน',
    numberTitle: 'ลำดับ',
    info: 'ข้อมูล',
    detail: 'รายละเอียด',
    odds: 'คี่',
    stake: 'เดิมพัน',
    bettingDetail: 'รายละเอียดการเล่น',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    profileSetting: 'ตั้งค่าบัญชี',
    bettingInfo: 'รายละเอียดการเล่น',
    maxPerMatch: 'แทงสูงสุดต่อคู่',
    minBet: 'เดิมพันขั้นต่ำ',
    maxBet: 'เดิมพันสูงสุด',
    parlayMin: 'คู่ต่ำสุดต่อบิล',
    parlayMax: 'คู่สูงสุดต่อบิล',
    currentPassword: 'รหัสผ่านปัจุบัน',
    newPassword: 'รหัสผ่านใหม่',
    verifyPassword: 'ยืนยันรหัสผ่านใหม่',
    preference: 'การตั้งค่า',
    defaultLanguage: 'เมื่อเข้าสู่ระบบจะใช้ภาษา',
    bath: 'บาท',
    pleaseWaitUpdating: 'โปรดรอสักครู่ กำลังอัพเดท',
    AuthInvalid: 'ยูซเซอร์เนมหรือพาสเวิร์ดไม่ถูกต้อง',
    AuthSomethingWentWrong: 'เกิดข้อผิดพลาด ไม่สามารถล็อคอินได้',
    ticket: {
      stake: 'เงินเดิมพัน',
      totalOdds: 'ราคารวม',
      payout: 'จ่าย',
      step: 'สเต็ป',
      mixParlay: 'บอลสเต็ป',
      minBet: 'เดิมพันขั้นต่ำ',
      maxBet: 'เดิมพันสูงสุด',
      maxPayout: 'จ่ายสูงสุด',
    },
    selectAll: 'เลือกทั้งหมด',
    backToStatement: 'กลับไปยังหน้ารายการ',
    winlose: 'ได้-เสีย',
    matchesNotEnoughForBet: 'ไม่พบคู่แข่งขัน',
    oddsHasBeenChanged: 'ราคามีการเปลี่ยนแปลง',
    ticketConfirm: {
      title: 'ยืนยันการเดิมพัน',
      message: 'กรุณากดปุ่ม "ตกลง" เพื่อยืนยันการเดิมพัน',
    },
    ticketError: {
      MatchInactive: 'แมตช์แข่งขันปิดแล้ว',
      MatchKO: 'แมตช์แข่งขันปิดแล้ว',
      InvalidOdds: 'ราคาเปลี่ยน',
      InvalidMatchRate: 'ราคาของแมตช์แข่งขันไม่ถูกต้อง',
      OddsLessThanLimit: 'ราคาของแมตช์แข่งขันไม่ถูกต้อง',
      MatchNotFound: 'ไม่พบแมตช์แข่งขัน',
      compareOdds: 'จาก {old} เป็น {new}',
    },
    ticketSlip: {
      stake: 'จำนวนเงิน',
      totalOdds: 'ราคา',
      status: 'สถานะ',
      winlose: 'ยอดได้เสีย',
    },
  },
  en: {
    cancel: 'cancel',
    remove: 'remove',
    submit: 'submit',
    change: 'change',
    changeOdds: 'change',
    loading: 'loading',
    dataNotFound: 'Data not found',
    ballStep: 'BallStep',
    statement: 'Statement',
    fullBetList: 'Full Bet List',
    result: 'Result',
    account: 'Account',
    live: 'Live',
    program: 'Program',
    logout: 'Logout',
    username: 'Username',
    signIn: 'Sign In',
    password: 'Password',
    earlyMarket: 'Early Market',
    games: 'Games',
    football: 'Football',
    outstanding: 'Outstanding',
    betCredit: 'Bet Credit',
    tickets: 'Tickets',
    markets: 'Markets',
    myBets: 'My Bets',
    hdpOu: 'HDP & OU',
    fhHdpOu: 'FH HDP & OU',
    oddsEven: '1x2 & OE',
    fullBet: 'Full Bet',
    noPendingBets: 'No pending bets',
    emptyTicket: 'Empty Ticket',
    maxPayout: 'Max Payout',
    print: 'Print',
    selectLeague: 'Select League',
    time: 'Time',
    fullTime: 'Full Time',
    event: 'Event',
    hdp: 'HDP',
    home: 'Home',
    away: 'Away',
    goal: 'Goal',
    over: 'Over',
    under: 'Under',
    firstHalf: 'First Half',
    draw: 'Draw',
    odd: 'Odd',
    even: 'Even',
    date: 'Date',
    remark: 'Remark',
    type: 'Type',
    totalAmount: 'Total',
    totalTurnOver: 'Turnover',
    totalWinLose: 'WinLose',
    commission: 'Comm',
    betting: 'Betting',
    winLoseTransaction: 'WinLose',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    chooseDate: 'Choose Date',
    today: 'Today',
    yesterday: 'Yesterday',
    ok: 'OK',
    match: 'Match Teams',
    htScore: 'First Half Score',
    ftScore: 'Full Time Score',
    status: 'Status',
    resultTitle: 'Result',
    betListTitle: 'Full Bet List',
    statementTitle: 'Statement',
    numberTitle: 'NO.',
    info: 'Info',
    detail: 'Detail',
    odds: 'Odds',
    stake: 'Stake',
    bettingDetail: 'Betting Details',
    changePassword: 'Change Password',
    profileSetting: 'Profile Setting',
    bettingInfo: 'Betting Info',
    maxPerMatch: 'Max Per Match',
    minBet: 'Min bet',
    maxBet: 'Max bet',
    parlayMin: 'Parlay Min',
    parlayMax: 'Parlay Max',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    verifyPassword: 'Verify Password',
    preference: 'Preference',
    defaultLanguage: 'Default Language',
    bath: 'bath',
    pleaseWaitUpdating: 'Please wait, updating',
    AuthInvalid: 'Invalid username or password',
    AuthSomethingWentWrong: 'Something went wrong',
    ticket: {
      stake: 'Stake',
      totalOdds: 'Total Odds',
      payout: 'Payout',
      step: 'Step',
      mixParlay: 'Mix parlay',
      minBet: 'Min bet',
      maxBet: 'Max bet ',
      maxPayout: 'Max payout',
    },
    selectAll: 'Select All',
    backToStatement: 'Back to statement',
    winlose: 'Winlose',
    matchesNotEnoughForBet: 'Matches not enough for bet!',
    oddsHasBeenChanged: 'Odds has been changed',
    ticketConfirm: {
      title: 'Confirm bet',
      message: 'Please click "OK" button to confirm bet',
    },
    ticketError: {
      MatchInactive: 'Match has been closed',
      MatchKO: 'Match has been closed',
      InvalidOdds: 'Odds has been changed',
      OddsLessThanLimit: 'Invalid odds',
      InvalidMatchRate: 'Invalid match rate',
      MatchNotFound: 'Match not found',
      compareOdds: 'from {old} to {new}',
    },
    ticketSlip: {
      stake: 'Stake',
      totalOdds: 'Total odds',
      status: 'Status',
      winlose: 'Win/Lose',
    },
  },
};
