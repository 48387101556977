import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { apiFetch } from '../composition/useAxios';
import router from '../router';
import { useMemberStore } from './member';
import { useLoadingStore } from './wait';
import * as Sentry from '@sentry/vue';

import type { LoginInput } from '../interfaces/api/LoginInput.interface';
import { useBetTicketStore } from './betTicket';

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      version: '1.0.0',
      isLoggedIn: false,
      loginErrorMessage: '',
      internalError: '',
    };
  },
  actions: {
    async login(input: LoginInput) {
      const memberStore = useMemberStore();
      const loadingStore = useLoadingStore();
      const betTicketStore = useBetTicketStore();
      const lang = localStorage.getItem('lang') || 'th';
      betTicketStore.$state.selectedTeamList = [];
      betTicketStore.$state.ticketList = [];
      try {
        loadingStore.start('login');
        this.loginErrorMessage = '';
        this.internalError = '';
        const { data, error } = await apiFetch('/login', {
          method: 'POST',
          data: { ...input },
        });
        if (data?.value?.token) {
          this.isLoggedIn = true;
          useStorage('APP_TOKEN', data.value?.token);
          memberStore.getMember();
          await router.push('/rules');
          this.loginErrorMessage = '';
        } else if (error.value) {
          if (error.value.response?.data?.statusCode === 401) {
            this.loginErrorMessage = 'AuthInvalid';
          }
          this.loginErrorMessage =
            lang === 'th'
              ? `เกิดข้อผิดพลาด ไม่สามารถล็อคอินได้ (${error.value})`
              : `Something went wrong (${error.value})`;
          Sentry.captureException(error);
        }
      } catch (error) {
        this.internalError = error;
        this.loginErrorMessage =
          lang === 'th'
            ? `เกิดข้อผิดพลาด ไม่สามารถล็อคอินได้ (${error})`
            : `Something went wrong (${error})`;
        Sentry.captureException(error);
      } finally {
        loadingStore.end('login');
      }
    },
    async logout() {
      const loadingStore = useLoadingStore();
      try {
        loadingStore.start('logout');
        const token = useStorage('APP_TOKEN', null);
        token.value = null;
        this.isLoggedIn = false;
        await router.replace('/login');
      } catch (error) {
        console.error(error);
      } finally {
        loadingStore.end('logout');
      }
    },
  },
});
